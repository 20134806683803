<template>
  <div class="p-visitorMemberList">
    <HeadingBand txt="担当者を選択してください。" />

    <div class="p-visitorMemberList_company">
      <img class="p-visitorMemberList_company_logo" :src="logo" />
      <p class="p-visitorMemberList_company_txt">
        {{ name }}
      </p>
    </div>

    <div class="p-visitorMemberList_list">
      <div
        v-for="(v, i) in members"
        :key="i"
        class="c-cardMember"
        @click="next(v.id, v.status)"
      >
        <div class="c-cardMember_status" :class="getStatus(v.status)[1]">
          {{ getStatus(v.status)[0] }}
        </div>
        <div class="c-cardMember_txt">
          <p class="c-cardMember_txt_ja">{{ v.ja }}</p>
          <p class="c-cardMember_txt_en">{{ v.en }}</p>
        </div>
      </div>
    </div>

    <div class="p-visitorMemberList_back">
      <BtnSmallBack txt="訪問先一覧に戻る" link="/visitor_company_list" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

import HeadingBand from "@/components/HeadingBand.vue";
import BtnSmallBack from "@/components/BtnSmallBack.vue";

export default {
  name: "VisitorMemberList",
  components: {
    HeadingBand,
    BtnSmallBack,
  },
  data: () => ({
    id: "",
    name: "-----",
    logo: "https://placehold.jp/200x200.png",
    members: [],
  }),
  async mounted() {
    this.id = localStorage.getItem("comp_id");
    const { data } = await axios.get("/companies.json?" + new Date().getTime());
    const target = data.filter((x) => x.id === this.id)[0];
    this.name = target.name;
    this.logo = target.logo;
    this.members = target.members;
  },
  methods: {
    next(id, status) {
      if (status !== 1) {
        alert("在席中ではありません。");
        return;
      }
      localStorage.setItem("memb_id", id);
      this.$router.push("/visitor_call_modal")
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return ["不在", "c-cardMember_status-absence"];
        case 1:
          return ["在席中", "c-cardMember_status-attendance"];
        case 2:
          return ["来客中", "c-cardMember_status-withVisitor"];
        default:
          return ["---", ""];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.p-visitorMemberList {
  &_company {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    width: 100%;

    &_logo {
      width: 60px;
      height: 60px;
    }

    &_txt {
      margin-left: 16px;
    }
  }

  &_list {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 30px auto 0;
    padding: 10px;
    width: 850px;
    height: 350px;

    .c-cardMember {
      &:not(:nth-child(4n-3)) {
        margin-left: 16px;
      }

      &:nth-child(n + 5) {
        margin-top: 16px;
      }
    }
  }

  &_back {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}

.c-cardMember {
  position: relative;
  display: flex;
  align-items: center;
  padding: 24px 16px 8px;
  border-radius: 11px;
  width: calc(25% - 12px);
  height: 92px;
  box-shadow: 0px 0px 6px #00000029;

  &_status {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-bottom: 1px;
    width: 57px;
    height: 19px;
    line-height: 1;
    font-size: 12px;
    color: $white;

    &-attendance {
      background-color: $blue;
    }

    &-absence {
      background-color: $gray;
    }

    &-withVisitor {
      background-color: $orange;
    }
  }

  &_txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    &_ja {
      line-height: 1;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.45em;
    }

    &_en {
      @include opensans;
      line-height: 1;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.45em;
      /* 3点リーダー */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
